var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',[_vm._t("header",function(){return [_c('CIcon',{attrs:{"name":"cil-grid"}}),_vm._v(" "+_vm._s(_vm.caption)+" ")]})],2),_c('CCardBody',[_c('CDataTable',{attrs:{"hover":"","striped":"","fields":_vm.fields,"items":_vm.sessions,"items-per-page":5,"sorter":{ resetable: true },"items-per-page-select":{ label: 'Sesiónes por página:',
                                values: [
                                  5,
                                  10,
                                  25,
                                  50,
                                  100,
                                  250,
                                  500
                                ]
      },"table-filter":{label: 'Filtro:', placeholder: 'Comience a escribir...'},"no-items-view":{noResults: 'No se han encontrado datos', noItems: 'No se han encontrado datos que cumplan las características indicadas'},"pagination":""},scopedSlots:_vm._u([{key:"id",fn:function(ref){
      var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.id_session)+" ")])]}},{key:"name",fn:function(ref){
      var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"acciones",fn:function(ref){
      var item = ref.item;
return [_c('td',[_c('CButton',{staticClass:"btn btn-info",staticStyle:{"margin-right":"3px"},attrs:{"color":"success","shape":"pill","size":"sm"},on:{"click":function($event){return _vm.seeSessionDetails(item.id_session)}}},[_vm._v(" Ver sesión ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }