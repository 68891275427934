<template>
  <div>
    <CRow>
      <CCol
        lg="12"
        sm="1"
      >
        <CTableWrapper>
          <template #header>
            <div class="d-flex justify-content-between">
              Sesiones de {{userName}} {{userSurname}}
            </div>
          </template>
        </CTableWrapper>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CTableWrapper from "../../components/SessionsTable";
import {checkUser, getUserDataPerID} from '../../utils/utils';
import "core-js/stable";
import "regenerator-runtime/runtime";
export default {
  name: "SesionTable",
  components: { CTableWrapper },
  data() {
    return {
      userName:"",
      userSurname:"",
      URL_user_id:null,
    }
  },
  created() {
    if (!checkUser()) return;
    this.receiveData();
  },
  methods: {
    receiveData(){
      this.getUserID();
      this.getUser();
    },
    getUserID(){
      if (!this.$route.params.id==this.URL_user_id) return;
      this.URL_user_id=this.$route.params.id;
    },
    getUser(){
      let userData = getUserDataPerID(this.URL_user_id);
      userData.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.userName=result.user.name;
        this.userSurname=result.user.surname;
      });
    },
  },
};
</script>