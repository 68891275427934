<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-grid" /> {{ caption }}
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        hover
        striped
        :fields="fields"
        :items="sessions"
        :items-per-page="5"
        :sorter="{ resetable: true }"
        :items-per-page-select="{ label: 'Sesiónes por página:',
                                  values: [
                                    5,
                                    10,
                                    25,
                                    50,
                                    100,
                                    250,
                                    500
                                  ]
        }"
        :table-filter="{label: 'Filtro:', placeholder: 'Comience a escribir...'}"
        :no-items-view="{noResults: 'No se han encontrado datos', noItems: 'No se han encontrado datos que cumplan las características indicadas'}"
        pagination
      >
        <template #id="{ item }">
          <td>
            {{ item.id_session }}
          </td>
        </template>

        <template #name="{ item }">
          <td>
            {{ item.name }}
          </td>
        </template>

        <template #acciones="{ item }">
          <td>

            <CButton
              color="success"
              shape="pill"
              class="btn btn-info"
              size="sm"
              style="margin-right: 3px;"
              @click="seeSessionDetails(item.id_session)"
            >
              Ver sesión
            </CButton>


          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import "core-js/stable";
import "regenerator-runtime/runtime";
import {getSessionsDataPerUserID, checkUser, changeDateFormat} from '../utils/utils';
export default {
  name: "TablaSesiones",
  props: {
    fields: {
      type: Array,
      default() {
        return [
          {
            key: "date",
            label: "Fecha de la sesión"
          },
          {
            key: "acciones",
            sorter: false,
            filter: false
          }
        ];
      },
    },
    caption: {
      type: String,
      default: "Table",
    }
  },
  data() {
    return {
      deleteModal: false,
      session: {},
      sessions: [],
      URL_user_id:null,
    }
  },
  created() {
    if (!checkUser()) return;
    this.receiveData();
  },
  methods: {
    receiveData(){
      this.getUserID();
      this.getSessions();
    },
    getUserID(){
      if (!this.$route.params.id==this.URL_user_id) return;
      this.URL_user_id=this.$route.params.id;
    },
    /**
     * Función para recibir los datos que se pasaran al componente de la tabla
     */
    getSessions() {
      let sessionData = getSessionsDataPerUserID(this.URL_user_id);
      sessionData.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        let element = {};
        let data = [];
        result.session.forEach(session => {
          element = {};
          element.id_session=session.id_session;
          element.date = changeDateFormat(session.date);
          data.push(element);
        });
        this.sessions = data;
      });
    },
    /**
     * Este evento de click, se ejecuta al hacer click en el boton de Ver sesión de la tabla acciones
     * te lleva a la página para ver los detalles de esa sesión
     * 
     * @param {number} id - El ID de la sesión sobre el que se ha llamado el evento ver sesión
     */
    seeSessionDetails(id) {
      this.$router.push("/user/sessions/"+this.$route.params.id+"/details/" + id);
    },
  }
}
</script>